<template>
  <div>
    <ContextualSearch
      :restore-filters="false"
      :loading="loading"
      :applied-sort="orderByValue"
      :applied-sort-direction="orderDirection"
      :start-value="searchInput"
      :order-by-options="orderBy"
      @sort-order-changed="sortOrderChanged"
      @search="search"
    />
    <fw-panel title="Resultados" :counter="courses.length" :counter-total="totalResults" class="my-5">
      <LoadingPlaceholder v-if="loading" />
      <fw-panel-info v-else-if="!loading && !courses.length" empty>
        Ainda não foram definidos cursos.
      </fw-panel-info>
      <div
        v-else-if="!loading && courses.length > 0"
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4"
      >
        <CardManageCourse
          v-for="item in courses"
          :key="item.key"
          :item="item"
          :stats="stats?.[item.key] || {}"
          :validations="validations?.[item.key] || {}"
          @edit="$emit('edit', $event)"
          @delete="$emit('delete', $event)"
        />
      </div>

      <BlockPagination
        v-if="totalPages > 1 && !loading"
        :per-page="limit"
        :total="totalResults"
        :total-pages="totalPages"
        :current.sync="page"
        @page-changed="pageChanged"
      />
    </fw-panel>
  </div>
</template>

<script>
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CardManageCourse from '@/components/cards/manage/CardManageCourse'
// import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    ContextualSearch,
    BlockPagination,
    LoadingPlaceholder,
    CardManageCourse,
  },
  props: {},

  data() {
    return {
      filterByType: 'all',
      orderBy: [
        {
          key: 'title',
          label: 'Nome',
          type: 'string',
        },
      ],
      searchInput: '',
      orderByValue: 'title',
      orderDirection: 'ASC',
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 100,
      courses: [],
      users: {},
      validations: {},
      loading: true,
      activeModal: null,
      stats: {},
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    api() {
      return this.$store.state.api.base
    },
  },

  mounted() {
    this.getUrlParams()
    this.getCourses()
  },

  methods: {
    search(data) {
      console.log('search', data)
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.$emit('searching')

      this.setUrlParams()
      this.getCourses()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getCourses()
    },

    async getCourses() {
      this.loading = true
      const query = {
        limit: this.limit,
        page: this.page,
      }
      console.log('query', query)
      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      try {
        const result = await this.api.getManagerCourses(query)
        console.log('getCourses :>> ', result)
        this.totalResults = result.pagination?.total_items
        this.totalPages = result.pagination?.total_pages
        this.page = result.pagination?.current_page
        this.courses = result.courses
        this.validations = result.validations
        this.stats = result.stats
      } catch (error) {
        console.error('getCourses Error: ', error)
      }

      this.loading = false
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getCourses()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },
  },
}
</script>
