<template>
  <div
    class="rounded-xl transition-all hover:shadow-lg hover:bg-white cursor-pointer overflow-hidden relative bg-gray-200 bg-opacity-80 text-center flex-shrink-0"
  >
    <router-link
      :to="{ name: 'manage-course-editions', params: { key: item.key } }"
      class="flex-1 cursor-pointer text-left"
    >
      <div class="flex flex-col p-4 text-gray-500 h-full gap-4">
        <div class="flex justify-between gap-2">
          <div class="text-sm gap-1 font-semibold flex items-center">
            <fw-icon-cube class="h-5 w-5 opacity-80" />
            <div class="flex-1 opacity-80">Curso {{ item.prefix | uppercase }}</div>
          </div>
        </div>
        <div class="flex-1">
          <div v-if="item.title != null && item.title.length > 0" class="text-gray-800 text-lg font-semibold">
            <v-clamp autoresize :max-lines="4">{{ item.title }}</v-clamp>
          </div>
          <div v-else class="text-gray-500 text-opacity-50 text-center w-4/5 mx-auto">
            Curso sem título
          </div>
        </div>
        <div v-if="$slots.secondline">
          <slot name="secondline"></slot>
        </div>
        <div class="flex flex-col gap-2">
          <fw-tag :counter="stats?.editions || 0" force-counter expanded type="xlight">Edições</fw-tag>
          <fw-tag :counter="stats?.enrollments || 0" force-counter expanded type="xlight">Certificados</fw-tag>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    validations: {
      type: Object,
      default: () => {
        return {
          can_delete: true,
        }
      },
    },
    stats: {
      type: Object,
      default: () => {
        return {
          editions: 0,
          enrollments: 0,
        }
      },
    },
  },
}
</script>
